import React from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AuthContext from '../../../../AuthContext';
import withAuth from '../../../../hocWrappers/withAuth';
import mtzApis from '../../../../services';
import MessageManager from "../../messages/MessageManager";
import RoomManager from '../../rooms/RoomManager';

const { chatService } = mtzApis;

function InstantChatManager({ newRoomEvent, newMessageEvent }) {
    let [active, setActive] = React.useState(null);
    let [unreadStats, setUnreadStats] = React.useState();
    let [openChat, setOpenChat] = React.useState(false);
    const collapseOpenInstantChatRef = React.useRef();
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        if (!me || !me.sessionId)
            return;
        init();
    }, [me.sessionId]);

    const init = async () => {
        // load unreads
        let stats = await chatService.getMessageStats(new URLSearchParams(`notSeenBy=${me.userId}`));
        if (stats)
            setUnreadStats(stats);
    };

    // room events
    React.useEffect(() => {
        if (newRoomEvent.action === 'VIEWED' && newRoomEvent.resource) {
            setActive(newRoomEvent.resource);
            if (!openChat)
                collapseOpenInstantChatRef.current.click();
        }
    }, [newRoomEvent]);

    // message events
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        if (newMessageEvent.action === 'DELETED') {
            if (!msg.viewerIds.includes(me.userId))
                return;
            setUnreadStats(prev => ({ all: { count: prev.all.count <= 0 ? 0 : prev.all.count - 1 } }));
        }

        if (newMessageEvent.action === 'CREATED') {
            if (msg.viewerIds.includes(me.userId))
                return;
            setUnreadStats(prev => ({ all: { count: prev.all.count < 100 ? prev.all.count + 1 : prev.all.count } }));
        }

        if (newMessageEvent.action === 'UPDATED') {
            (async () => {
                let stats = await chatService.getMessageStats(new URLSearchParams(`notSeenBy=${me.userId}&limit=100`));
                setUnreadStats(stats);
            })();
        }
    }, [newMessageEvent]);

    return (
        <div className='d-flex flex-column' style={{ width: '600px' }}>
            {
                !!me && !!me.userId && !!me.sessionId &&
                <>
                    <div className='d-flex align-items-center'>
                        {
                            openChat &&
                            <button onClick={() => {
                                setTimeout(() => {
                                    setOpenChat(!openChat);
                                }, 0);
                            }} className='btn btn-secondary btn-sm' data-toggle='collapse' data-target='#collapse-instant-chat'>
                                <span className='fa fa-times'></span>
                            </button>
                        }

                        <span className='flex-fill'></span>

                        {
                            unreadStats && unreadStats.all && unreadStats.all.count > 0 &&
                            <span><b style={{ position: 'relative', top: '-23px', right: '-5px' }}
                                className='btn btn-danger badge mtz-b3 text-white'>
                                {unreadStats.all.count < 100 ? unreadStats.all.count : '99+'}
                            </b></span>
                        }

                        <div ref={collapseOpenInstantChatRef} onClick={() => setOpenChat(!openChat)}
                            data-toggle='collapse' data-target='#collapse-instant-chat'
                            className='rounded-top border border-dark px-4 py-2 bg-primary text-white mtz-gap-8 d-flex align-items-center mtz-cursor-pointer'>
                            Messages <span className='fa fa-message'></span>
                        </div>

                        <span data-toggle='tooltip' title='Open large chat' className=''>
                            <NavLink to='/chat' className='ml-auto'>
                                <button className='btn'>
                                    <span className='fa fa-external-link'></span>
                                </button>
                            </NavLink>
                        </span>
                    </div>

                    <div id='collapse-instant-chat'
                        className="collapse p-1 mtz-rounded-8 border border-dark bg-white">

                        {
                            !unreadStats ?
                                'Chat service not available' :
                                (
                                    active ?

                                        <>
                                            <button onClick={() => setActive()} className='btn btn-link btn-sm font-weight-bold'>
                                                <i className='fa fa-arrow-left'></i> Back to rooms
                                            </button>
                                            <div className='bg-light h-100 vh-75'>
                                                <MessageManager room={active} />
                                            </div>
                                        </> :
                                        <div className='h-100 vh-75'>
                                            <RoomManager room={active} onChange={updated => setActive(updated)} />
                                        </div>
                                )
                        }
                    </div>
                </>
            }
        </div >
    );
}

const mapStateToProps = state => ({
    newMessageEvent: state.message_topic.new_uievent,
    newRoomEvent: state.room_topic.new_uievent,
});

const InstantChatManagerWrapper = connect(
    mapStateToProps
)(InstantChatManager);

export default withAuth(InstantChatManagerWrapper);